@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,300&family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* font-family: 'Open Sans', sans-serif; */
  font-family: "Jost", sans-serif;
  color: #242526;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f3f3f3; */
  /* background-color: #f6f6f6; */
  /* background-color: var(--bg-accent); */
  /* background: #f7f7f7; */
  font-weight: 500;
  font-size: 14px;
  /* line-height: 1.5em; */
  color: #333;
  font-weight: 400;
  line-height: 1.6em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --orange: #bf421d;
  --red: #ea4335;
  --blue: #002d72;
  --blueText: #002d72;
  --darkBlue: #557dbf;
  --green: #1fb57b;
  --darkGreen: #129b6a;
  --darkGray: #212121;
  --gray: #c6c6cc;
  --lightGreen: #85d3bd;
  --yellow: #fddf71;
  --darkYellow: #d7b258;
  --borderColor: #e6e6e6;
  --border: 1px solid #e6e6e6;
  /* --pageMaxWidth: 1200px; */
  --pageMaxWidth: 1400px;
  --backgroundHover: rgb(246, 246, 246);
  --transitionSpeed: 500ms;
  --boxShadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.085);
  --boxShadow2: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  --boxShadowABB: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  --boxShadowJ: rgba(0, 0, 0, 0.1) 0px 2px 3px 0px;
  --openSans: "Open Sans", sans-serif;
  --nunito: "Nunito", sans-serif;
  --lato: "Lato", sans-serif;
  --jost: "Inter", sans-serif;
  --headerHeight: 70px;
}

body,
html {
  height: 100%;
}

button,
body,
p,
input,
textarea {
  font-family: var(--jost);
}

body,
p {
  color: #242526;
}

h1 {
  font-family: var(--jost);
  /* font-family: var(--nunito); */
  margin: 10px 0;
  font-weight: 800;
  font-size: 26px;
  /* margin-bottom: 15px; */
}

h2 {
  /* font-family: var(--nunito); */
  font-family: var(--jost);
  /* color: var(--orange); */
  /* font-family: var(--openSans); */
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
}

h3 {
  font-family: var(--jost);
  /* font-family: var(--lato); */
  font-size: 18px;
  font-weight: 400;
  /* margin-bottom: 10px; */
}
h4 {
  font-family: var(--nunito);
  /* font-family: var(--lato); */
  font-size: 15px;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.title {
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 88vh;
}

.form-page-container .card {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  max-width: 580px;
  padding: 30px 1.5em;
  transition: var(--transitionSpeed);
}

@media only screen and (max-width: 900px) {
  .form-page-container .card {
    padding: 30px 1.5em !important;
    box-shadow: none !important;
  }

  .form-page-container {
    display: block;
  }
}
