@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,300&family=Open+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* font-family: 'Open Sans', sans-serif; */
  font-family: "Jost", sans-serif;
  color: #242526;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f3f3f3; */
  /* background-color: #f6f6f6; */
  /* background-color: var(--bg-accent); */
  /* background: #f7f7f7; */
  font-weight: 500;
  font-size: 14px;
  /* line-height: 1.5em; */
  color: #333;
  font-weight: 400;
  line-height: 1.6em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --orange: #bf421d;
  --red: #ea4335;
  --blue: #002d72;
  --blueText: #002d72;
  --darkBlue: #557dbf;
  --green: #1fb57b;
  --darkGreen: #129b6a;
  --darkGray: #212121;
  --gray: #c6c6cc;
  --lightGreen: #85d3bd;
  --yellow: #fddf71;
  --darkYellow: #d7b258;
  --borderColor: #e6e6e6;
  --border: 1px solid #e6e6e6;
  /* --pageMaxWidth: 1200px; */
  --pageMaxWidth: 1400px;
  --backgroundHover: rgb(246, 246, 246);
  --transitionSpeed: 500ms;
  --boxShadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.085);
  --boxShadow2: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  --boxShadowABB: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  --boxShadowJ: rgba(0, 0, 0, 0.1) 0px 2px 3px 0px;
  --openSans: "Open Sans", sans-serif;
  --nunito: "Nunito", sans-serif;
  --lato: "Lato", sans-serif;
  --jost: "Inter", sans-serif;
  --headerHeight: 70px;
}

body,
html {
  height: 100%;
}

button,
body,
p,
input,
textarea {
  font-family: "Inter", sans-serif;
  font-family: var(--jost);
}

body,
p {
  color: #242526;
}

h1 {
  font-family: "Inter", sans-serif;
  font-family: var(--jost);
  /* font-family: var(--nunito); */
  margin: 10px 0;
  font-weight: 800;
  font-size: 26px;
  /* margin-bottom: 15px; */
}

h2 {
  /* font-family: var(--nunito); */
  font-family: "Inter", sans-serif;
  font-family: var(--jost);
  /* color: var(--orange); */
  /* font-family: var(--openSans); */
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
}

h3 {
  font-family: "Inter", sans-serif;
  font-family: var(--jost);
  /* font-family: var(--lato); */
  font-size: 18px;
  font-weight: 400;
  /* margin-bottom: 10px; */
}
h4 {
  font-family: "Nunito", sans-serif;
  font-family: var(--nunito);
  /* font-family: var(--lato); */
  font-size: 15px;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.title {
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 88vh;
}

.form-page-container .card {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  max-width: 580px;
  padding: 30px 1.5em;
  transition: 500ms;
  transition: var(--transitionSpeed);
}

@media only screen and (max-width: 900px) {
  .form-page-container .card {
    padding: 30px 1.5em !important;
    box-shadow: none !important;
  }

  .form-page-container {
    display: block;
  }
}

main {
  background-color: #ffffff;
}

main .main-wrapper {
  max-width: var(--pageMaxWidth);
  margin: 0 auto;
}

main section {
  padding: 0 1.5em;
  margin: 40px 0 70px 0;
}

main .no-padding {
  padding: 0 !important;
}
/*# sourceMappingURL=Main.css.map */
#welcome {
  background: linear-gradient(to right, #fafafa 0%, white 41%, #f2f2f2 77%, #fefefe 100%);
  height: 100%;
  min-height: 600px;
  position: relative;
  overflow: hidden;
}

.home-img {
  position: relative;
  min-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  background-size: 400% 400%;
  -webkit-animation: bgFlow 20s ease infinite;
  animation: bgFlow 80s ease infinite;
  text-align: center;
}

.home-img h1 {
  font-size: 2.5em;
  font-weight: 800;
  font-family: var(--h1);
  letter-spacing: 1px;
}

.home-img .sub-heading {
  font-size: 20px;
  line-height: 1.5em;
  padding: 0 18px;
  letter-spacing: 1px;
}

.sub-heading {
  margin-top: 10px;
  -webkit-animation: fadeIn 1.7s ease forwards 1.55s;
          animation: fadeIn 1.7s ease forwards 1.55s;
  opacity: 0;
  text-shadow: 1px 2px 4px #283145;
  z-index: 1;
}

.projects-btn {
  padding: 1.2em 2.3em;
  background: #283145c7;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  border: 1px solid white;
  border-radius: 2px;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  opacity: 0;
  transition: 230ms;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  -webkit-animation: fadeIn 1s ease forwards 1.8s;
  animation: fadeIn 1s ease forwards 1.8s;
  display: flex;
  align-items: center;
}

.projects-btn:hover {
  background-color: var(--orange);
  border-color: var(--orange);
  color: #ffffff;
}

.projects-btn svg {
  height: 12px;
  margin-left: 10px;
}

.home-img a span {
  position: relative;
  z-index: 1;
}

.home-img a {
  text-decoration: none;
}

.home-img a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: var(--color-icon--opp);
  transition: all 0.5s ease-in-out;
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.content,
.home-img a {
  z-index: 5;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transform: translateY(-100px);
  z-index: 11111;
}

.content #home-img-svg {
  width: 85%;
}

@-webkit-keyframes line-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes line-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes fill {
  from {
    fill: rgba(255, 255, 255, 0.244);
  }
  to {
    fill: white;
  }
}

@keyframes fill {
  from {
    fill: rgba(255, 255, 255, 0.244);
  }
  to {
    fill: white;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-img a:hover:after {
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

@-webkit-keyframes bgFlow {
  0% {
    background-position: 11% 0%;
  }
  50% {
    background-position: 90% 100%;
  }
  100% {
    background-position: 11% 0%;
  }
}

@keyframes bgFlow {
  0% {
    background-position: 11% 0%;
  }
  50% {
    background-position: 90% 100%;
  }
  100% {
    background-position: 11% 0%;
  }
}

@media only screen and (max-width: 1100px) {
  .home-img h1 {
    margin-top: -55px;
  }
  .home-img .sub-heading {
    font-size: 18px;
    line-height: 1.5em;
    padding: 0px 25px;
    letter-spacing: 1px;
  }
}
/*# sourceMappingURL=HomeScreen.css.map */
#particle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #2d364c 0%, #252d3f 100%);
  z-index: 1;
}

#particle-background #tsparticles {
  height: 100%;
}

#particle-background .background-switcher-container {
  position: absolute;
  top: 5%;
  right: 20px;
  cursor: pointer;
}

#particle-background .background-switcher-container .edit-background-button {
  background-color: rgba(255, 255, 255, 0.611);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}

#particle-background .background-switcher-container .edit-background-button:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

#particle-background .background-switcher-container .edit-background-button svg {
  width: 20px;
}

#particle-background .background-switcher-container .background-options-wrapper {
  position: relative;
}

#particle-background .background-switcher-container .background-options-wrapper .background-options__button {
  transition: 300ms;
  color: rgba(255, 255, 255, 0.75);
  position: absolute;
  white-space: nowrap;
  -webkit-animation-duration: 650ms;
  animation-duration: 650ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  top: 0;
  right: 0;
}

#particle-background .background-switcher-container .background-options-wrapper .background-options__button:hover {
  color: rgba(255, 255, 255, 0.9);
}

#particle-background .background-switcher-container .background-options-wrapper .background-options__button svg {
  margin-left: 6px;
  margin-bottom: -2px;
  height: 14px;
}

#particle-background .background-switcher-container .background-options-wrapper .background-options__button:nth-child(1) {
  animation-name: buttonSlide1;
  -webkit-animation-name: buttonSlide1;
}

#particle-background .background-switcher-container .background-options-wrapper .background-options__button:nth-child(2) {
  -webkit-animation-name: buttonSlide2;
  animation-name: buttonSlide2;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}

#particle-background .background-switcher-container .background-options-wrapper .background-options__button:nth-child(3) {
  -webkit-animation-name: buttonSlide3;
  animation-name: buttonSlide3;
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}

@-webkit-keyframes buttonSlide1 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(10px, 25px);
  }
}

@keyframes buttonSlide1 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(10px, 25px);
  }
}

@-webkit-keyframes buttonSlide2 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-55px, 10px);
  }
}

@keyframes buttonSlide2 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-55px, 10px);
  }
}

@-webkit-keyframes buttonSlide3 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-60px, -30px);
  }
}

@keyframes buttonSlide3 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-60px, -30px);
  }
}
/*# sourceMappingURL=ParticleBackground.css.map */
#home-img-svg {
  -webkit-animation: fill 1.5s ease forwards 1.2s;
          animation: fill 1.5s ease forwards 1.2s;
}

#home-img-svg path:nth-child(1) {
  stroke-dasharray: 273.9136962890625px;
  stroke-dashoffset: 273.9136962890625px;
  -webkit-animation: line-animation 1.2s ease forwards;
          animation: line-animation 1.2s ease forwards;
}

#home-img-svg path:nth-child(2) {
  stroke-dasharray: 151.55479431152344px;
  stroke-dashoffset: 151.55479431152344px;
  -webkit-animation: line-animation 1.5s ease forwards;
          animation: line-animation 1.5s ease forwards;
}

#home-img-svg path:nth-child(3) {
  stroke-dasharray: 211.50953674316406px;
  stroke-dashoffset: 211.50953674316406px;
  -webkit-animation: line-animation 1.2s ease forwards;
          animation: line-animation 1.2s ease forwards;
}

#home-img-svg path:nth-child(4) {
  stroke-dasharray: 145px;
  stroke-dashoffset: 145.2342529296875px;
  -webkit-animation: line-animation 1.2s ease forwards;
          animation: line-animation 1.2s ease forwards;
}

#home-img-svg path:nth-child(5) {
  stroke-dasharray: 211.26840209960938px;
  stroke-dashoffset: 211.26840209960938px;
  -webkit-animation: line-animation 1.2s ease forwards;
          animation: line-animation 1.2s ease forwards;
}

#home-img-svg path:nth-child(6) {
  stroke-dasharray: 143.42391967773438px;
  stroke-dashoffset: 143.42391967773438px;
  -webkit-animation: line-animation 1.2s ease forwards;
          animation: line-animation 1.2s ease forwards;
}

#home-img-svg path:nth-child(7) {
  stroke-dasharray: 83px;
  stroke-dashoffset: 83px;
  -webkit-animation: line-animation 1.2s ease forwards 0.5s;
          animation: line-animation 1.2s ease forwards 0.5s;
}

#home-img-svg path:nth-child(8) {
  stroke-dasharray: 123.7349853515625px;
  stroke-dashoffset: 123.7349853515625px;
  -webkit-animation: line-animation 1.2s ease forwards 0.5s;
          animation: line-animation 1.2s ease forwards 0.5s;
}

#home-img-svg path:nth-child(9) {
  stroke-dasharray: 112.73152160644531px;
  stroke-dashoffset: 112.73152160644531px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(10) {
  stroke-dasharray: 90px;
  stroke-dashoffset: 90px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(11) {
  stroke-dasharray: 211px;
  stroke-dashoffset: 211px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(12) {
  stroke-dasharray: 211px;
  stroke-dashoffset: 211px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(13) {
  stroke-dasharray: 151.55532836914062px;
  stroke-dashoffset: 151.55532836914062px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(14) {
  stroke-dasharray: 175px;
  stroke-dashoffset: 175px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(15) {
  stroke-dasharray: 87.2335205078125px;
  stroke-dashoffset: 87.2335205078125px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(16) {
  stroke-dasharray: 90.6915054321289px;
  stroke-dashoffset: 90.6915054321289px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(17) {
  stroke-dasharray: 120.99569702148438px;
  stroke-dashoffset: 120.99569702148438px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}

#home-img-svg path:nth-child(18) {
  stroke-dasharray: 145.2341766357422px;
  stroke-dashoffset: 145.2341766357422px;
  -webkit-animation: fadeIn 1.2s ease forwards 1.2s;
          animation: fadeIn 1.2s ease forwards 1.2s;
}
/*# sourceMappingURL=jimmy.css.map */
#three-particles {
  position: absolute !important;
  top: 0;
  left: 0;
  outline: none;
  width: 100%;
  height: 100%;
  z-index: 11;
}

@-webkit-keyframes buttonSlide1 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(10px, 25px);
  }
}

@keyframes buttonSlide1 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(10px, 25px);
  }
}

@-webkit-keyframes buttonSlide2 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-55px, 10px);
  }
}

@keyframes buttonSlide2 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-55px, 10px);
  }
}

@-webkit-keyframes buttonSlide3 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-60px, -30px);
  }
}

@keyframes buttonSlide3 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-60px, -30px);
  }
}
/*# sourceMappingURL=threeBackground.css.map */
.header-image-container {
  padding: 110px 0;
  width: 100%;
  color: #fff;
  position: relative;
  height: calc(100vh - var(--headerHeight));
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover !important;
}

.header-image-container h1 {
  position: relative;
  letter-spacing: 1px;
  font-size: 40px;
  z-index: 10;
  display: block;
  text-shadow: 1px 1px 6px #00000030;
}

@media only screen and (max-width: 600px) {
  .header-image-container {
    padding: 80px 0;
    height: 60vh;
  }
  .header-image-container h1 {
    letter-spacing: 1px;
    font-size: 36px;
  }
}

#wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

#wrapper-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

#scroll-down {
  display: block;
  position: relative;
  padding-top: 49px;
  text-align: center;
  position: absolute;
  bottom: 0;
  opacity: 0.8;
}

.arrow-down {
  display: block;
  margin: 0 auto;
  width: 10px;
  height: 38px;
}

.arrow-down:after {
  content: "";
  display: block;
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  transform: rotate(135deg);
}

#scroll-title {
  display: block;
  text-transform: uppercase;
  color: #ffffffc2;
  font-family: Helvetica Neue, Helvetica, Arial;
  font-size: 12px;
  letter-spacing: 0.1em;
}

#scroll-down::before {
  -webkit-animation: elasticus 2s cubic-bezier(1, 0, 0, 1) infinite;
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: elasticus 2s cubic-bezier(1, 0, 0, 1) infinite;
  /* IE 10+, Fx 29+ */
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 60px;
  background: #ffffff;
  content: " ";
}

@-webkit-keyframes elasticus {
  0% {
    transform-origin: 0% 0%;
    transform: scale(1, 0);
  }
  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }
  50.1% {
    transform-origin: 0% 100%;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: 0% 100%;
    transform: scale(1, 0);
  }
}

@keyframes elasticus {
  0% {
    transform-origin: 0% 0%;
    transform: scale(1, 0);
  }
  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }
  50.1% {
    transform-origin: 0% 100%;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: 0% 100%;
    transform: scale(1, 0);
  }
}
/*# sourceMappingURL=PageHeaderImage.css.map */
section#about {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

section#about .about-inner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

section#about .about-inner-wrapper .about__content {
  position: relative;
  padding: 0 20px;
  flex-basis: 500px;
}

section#about .about-inner-wrapper .about__content h2 {
  font-size: 54px;
  font-weight: bold;
  line-height: 1.4em;
}

section#about .about-inner-wrapper .about__content p {
  margin: 26px 0;
  font-size: 14px;
  line-height: 1.6rem;
  font-weight: 400;
}

section#about .about__graphic {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

section#about .about__graphic svg {
  display: block;
  width: 100%;
  max-width: 500px;
  height: auto;
}

@media only screen and (max-width: 650px) {
  section#about {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  section#about .about-inner-wrapper .about__content h2 {
    font-size: 42px;
  }
}

.a5bc420a-77d1-4260-b8e4-ecc2bb7a7d0f,
.b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b,
.b1b2c73c-9e21-464e-bc57-29b085fa4b84,
.b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8,
.e66afa13-fa46-4f1b-8bc3-061a97ff7f87 {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}

.b1b2c73c-9e21-464e-bc57-29b085fa4b84 {
  stroke: #264de4;
}

.fc9a5236-f453-4027-9306-71395085465e {
  fill: #264de4;
}

.a6d67499-94fe-48e0-b2f0-0b01122138f8 {
  fill: #fff;
}

.b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b {
  stroke: #e54c21;
}

.e9e8cfc3-cd04-4e93-9678-b77895814d9f {
  fill: #e54c21;
}

.a7d46a36-2de6-43d1-8e6f-1683debb0171 {
  fill: red;
}

.e1442802-9f38-4210-a460-3cf578bb5417 {
  fill: #599636;
}

.b4b027d5-194d-499b-967d-9b3b5453926a {
  fill: #6cac48;
}

.aa810f29-748f-47b3-a965-61319ae4b3c8 {
  fill: #c2bfbf;
}

.f1a52f01-54ca-44f6-829d-95d54c3d89fc {
  fill: #2a639a;
}

.fbe16779-d3b1-419d-876d-1666ca6c58f1 {
  fill: #efd81d;
}

.b5924130-c92b-42cf-859d-aacb5cef6a84 {
  fill: #3b813a;
}

.b834dfc7-277f-4c9e-a656-71c79c5ef852 {
  fill: #5dd2f3;
}

.bcb11178-8b52-410e-aa38-0ecc0f3d9437 {
  fill: #300;
}

.b2b89b4e-5594-4105-a0a9-2c9596b8ef68 {
  fill: #ff9a00;
}

.f89c03f3-ae52-471e-a58f-c01049733a93 {
  fill: #001e36;
}

.fdfd7200-8c70-4e4a-a25f-499e39b3ce9c {
  fill: #31a8ff;
}

.a5bc420a-77d1-4260-b8e4-ecc2bb7a7d0f {
  stroke: red;
}

.b3b0a662-b21c-4e77-a034-63b3d3ea1700 {
  fill: #470137;
}

.a93d6778-f477-442c-86ab-305764475ff6 {
  fill: #ff61f6;
}

.b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8,
.e66afa13-fa46-4f1b-8bc3-061a97ff7f87 {
  stroke: #000;
}

.e66afa13-fa46-4f1b-8bc3-061a97ff7f87 {
  stroke-dasharray: 4.93 4.93;
}
/*# sourceMappingURL=AboutScreen.css.map */
/* No CSS *//*# sourceMappingURL=DesignScreen.css.map */
.design-project-item-container {
  width: 100%;
  font-size: 12px;
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
}

.design-project-item-container .design-project__content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 640px;
  z-index: 111;
  border-radius: 2px;
  margin: 30px auto;
}

.design-project-item-container .design-project__content .design-project-inner-content .company {
  font-size: 13px;
  opacity: 0.65;
}

.design-project-item-container .design-project__content .design-project-inner-content h2 {
  letter-spacing: 2px;
  font-size: 42px;
  line-height: 1.5em;
  margin-top: 5px;
}

.design-project-item-container .design-project__content .design-project-inner-content p.description {
  margin-top: 15px;
  max-width: 440px;
}

.design-project-item-container .design-project__content .design-project-inner-content .content-container {
  margin-top: 80px;
}

.design-project-item-container .design-project__content .design-project-inner-content .content-container .logo-container {
  display: flex;
  flex-wrap: wrap;
}

.design-project-item-container .design-project__content .design-project-inner-content .content-container .logo-container .logo-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  margin-bottom: 5px;
  margin-right: 40px;
  /* max-width: 160px; */
  /* flex-wrap: wrap; */
  height: 50px;
}

.design-project-item-container .design-project__content .design-project-inner-content .content-container .logo-container .logo-wrapper img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.design-project-item-container .design-project__content .design-project-inner-content .content-container h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.design-project-item-container .design-project__image {
  position: relative;
  display: flex;
  width: 100%;
  flex-basis: 40%;
  max-width: 472px;
  align-items: center;
  height: 100%;
  max-height: 620px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.design-project-item-container .design-project__image img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 650px) {
  .design-project-item-container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .design-project-item-container .design-project-inner-content h2 {
    font-size: 28px !important;
  }
  .design-project-item-container .content-container {
    margin-top: 40px !important;
  }
}
/*# sourceMappingURL=DesignProject.css.map */
.card {
  margin: 0;
  padding: 1em;
  border-radius: 2px;
  color: #333333;
  box-shadow: var(--boxShadowJ);
  border: 1px solid var(--borderColor);
  overflow: hidden;
}
/*# sourceMappingURL=Card.css.map */
.project-item-container {
  width: 100%;
  font-size: 12px;
}

.project-item-container .card {
  position: relative;
  overflow: unset;
  padding: 0 !important;
  border-radius: 16px !important;
}

.project-item-container .project-item__image {
  position: relative;
}

.project-item-container .project-item__image .img-wrapper {
  height: 100%;
  height: 210px;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.project-item-container .project-item__image .img-wrapper img {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.project-item-container .project-item__image .img-wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.056);
}

.project-item-container .project-item__image .featured-icon {
  position: absolute;
  top: 5px;
  left: 5px;
}

.project-item-container .project-item__image .featured-icon svg {
  height: 24px;
}

.project-item-container .project-item__image .featured-icon svg path {
  fill: gold;
}

.project-item-container .project-item__image .icon-container {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  display: flex;
  color: #222222;
  border-radius: 16px;
}

.project-item-container .project-item__image .icon-container button {
  padding: 0;
  background: none;
  border: none;
}

.project-item-container .project-item__image .icon-container svg {
  cursor: pointer;
  height: 14px;
  color: #ffffff;
}

.project-item-container .project-item__image .icon-container svg path {
  stroke: #313131;
  stroke-width: 4px;
  stroke-linejoin: round;
}

.project-item-container .project-item__image .icon-container .icon-item-wrapper {
  padding: 0 9px;
  padding-top: 6px;
  background: linear-gradient(180deg, #00000091, #0000005e);
  margin: 0 0px;
  transition: 150ms;
}

.project-item-container .project-item__image .icon-container .icon-item-wrapper svg path,
.project-item-container .project-item__image .icon-container .icon-item-wrapper svg circle,
.project-item-container .project-item__image .icon-container .icon-item-wrapper svg line,
.project-item-container .project-item__image .icon-container .icon-item-wrapper svg polyline {
  stroke: #ffffff !important;
}

.project-item-container .project-item__image .icon-container .icon-item-wrapper:hover {
  background-color: #00000072;
}

.project-item-container .project-item__image .icon-container .icon-item-wrapper:nth-child(1) {
  padding-left: 15px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.project-item-container .project-item__image .icon-container .icon-item-wrapper#code-icon {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-right: 15px;
}

.project-item-container .project-item__image .featured {
  height: 350px !important;
}

.project-item-container .project-item__image .project-item__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background: linear-gradient(180deg, #00000063, #00000024);
  color: #222222;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 100%;
}

.project-item-container .project-item__image .project-item__info span,
.project-item-container .project-item__image .project-item__info h2 {
  text-shadow: 0px 0px 6px #00000080;
  color: #ffffff;
  position: relative;
}

.project-item-container .project-item__image .project-item__info span {
  font-size: 13px;
}

.project-item-container .project-item__image .project-item__info h2 {
  margin-bottom: 0;
}

.project-item-container .project-item__image .project-item__info h2 span {
  font-size: 15px;
}

.project-item-container .project-item__image .project-item__info .info-field span {
  position: relative;
}

.project-item-container .project-item__image .project-item__info .info-field span form {
  position: relative;
  padding: 1px 0;
}

.project-item-container .project-item__image .project-item__info .info-field span form button {
  top: 0;
  position: absolute;
  right: 22px;
}

.project-item-container .project-item__image .project-item__info .info-field span input,
.project-item-container .project-item__image .project-item__info .info-field span textarea {
  font-weight: inherit;
  font-size: inherit;
  text-shadow: 0px 0px 10px #000000;
  color: inherit;
  font-style: inherit;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ffffff;
  outline: 0px solid transparent;
  padding: 0;
}

.project-item-container .project-item__image .project-item__info .info-field span .form-control {
  margin: 0;
}

.project-item-container .project-item__image .project-item__info .info-field span .form-control label:after {
  content: unset;
}

.project-item-container .project-item__details {
  position: relative;
  padding: 15px;
  display: block;
  overflow: hidden;
  transition: 350ms;
  cursor: pointer;
  border-top: 1px solid var(--borderColor);
  background-color: white;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.project-item-container .project-item__details.details-open {
  height: auto !important;
}

.project-item-container .project-item__details button {
  background: none;
}

.project-item-container .project-item__details .details_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-item-container .project-item__details h3 {
  font-size: 13px;
  font-weight: 600;
  color: #434242;
}

.project-item-container .project-item__details .arrow {
  position: absolute;
  right: 20px;
  transition: 300ms;
}

.project-item-container .project-item__details .arrow svg {
  width: 16px;
  margin-top: 6px;
}

.project-item-container .project-item__details .arrow-open {
  transform: rotate(180deg);
}

.project-item-container .project-item__details .details-content_row {
  margin-top: 15px;
  line-height: 1.5em;
}

.project-item-container .project-item__details .details-content_row h4 {
  font-size: 13px;
  color: var(--blueText);
}

.project-item-container .item-active:after {
  content: '';
  width: 99%;
  height: 99%;
  border: 2px solid var(--blue);
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8px;
}

.map-container {
  width: 100%;
  height: 400px;
}

@media only screen and (max-width: 650px) {
  .project-item-container .project-item__image .featured.img-wrapper {
    height: 200px !important;
  }
  .project-item-container .project-item__image .img-wrapper {
    height: 165px !important;
  }
}
/*# sourceMappingURL=Project.css.map */
.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
  background: rgba(8, 8, 8, 0.657);
}
/*# sourceMappingURL=Backdrop.css.map */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.8);
}

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--blue);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=LoadingSpinner.css.map */
.modal {
  position: fixed;
  z-index: 100;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 85vh;
  border-radius: 5px;
  overflow: hidden;
  transition: 680ms ease;
  z-index: 1111111111111;
}

.modal.mobile {
  width: 430px;
  max-height: 840px;
}

.modal.desktop-full-screen {
  width: 100%;
  height: 100vh;
  border-radius: 0 !important;
}

.modal.desktop-full-screen .circle-inactive {
  box-shadow: unset !important;
  background: #fbbf07 !important;
  border: 1px solid #eabc26 !important;
}

.modal.desktop-full-screen .expand {
  position: relative;
}

.modal.desktop-full-screen .expand:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 2px;
  right: 0px;
  transform: rotate(45deg);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(80, 80, 80, 0.385);
}

.modal.desktop-full-screen .expand:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 2px;
  left: 0px;
  transform: rotate(225deg);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(80, 80, 80, 0.385);
}

.modal.desktop-full-screen .modal__body {
  position: relative;
}

.modal .modal__header {
  position: absolute;
  width: 100%;
  padding: 0.2rem 0.5rem;
  background: linear-gradient(#f7f6f5e8, #f1f1f1b8);
  color: black;
  display: flex;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
  box-shadow: 0 0px 3px 1px #00000040;
}

.modal .modal__header p {
  margin-top: 10px;
}

.modal .modal__header h3,
.modal .modal__header p {
  font-size: 16px;
}

.modal .modal__header svg {
  cursor: pointer;
  height: 18px;
}

.modal .modal__header .three-btn-container {
  position: relative;
  display: flex;
  z-index: 1111;
}

.modal .modal__header .three-btn-container .btn-circle {
  width: 15px;
  height: 15px;
  transition: 100ms;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.modal .modal__header .three-btn-container .close {
  background-color: #ff6054;
  border: 1px solid #f15956;
}

.modal .modal__header .three-btn-container .close:hover {
  background-color: #f74c40;
}

.modal .modal__header .three-btn-container .middle {
  background: linear-gradient(0, #d1d1d1, #f1f1f1);
  border: none;
  box-shadow: inset 0px 0px 2px 1px #0000002b;
}

.modal .modal__header .three-btn-container .expand {
  background-color: #17cb38;
  border: 1px solid #2bbb53;
}

.modal .modal__header .three-btn-container .expand:hover {
  background-color: #22b24b;
}

.modal .modal__header .device-switcher-container {
  display: flex;
  justify-content: space-between;
  width: auto;
  width: initial;
  max-width: 135px;
}

.modal .modal__header .device-switcher-container .device-switcher__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  padding: 1px 3px;
  position: relative;
  border-radius: 4px;
}

.modal .modal__header .device-switcher-container .device-switcher__icon-wrapper svg {
  stroke: #0f0f0f;
  height: 28px;
  width: 28px;
}

.modal .modal__header .device-switcher-container .device-switcher__icon-wrapper.icon-active {
  position: relative;
  background-color: #002d722b;
}

.modal .modal__header .device-switcher-container .device-switcher__icon-wrapper.icon-active svg {
  stroke: var(--blueText);
}

.modal .modal__header .device-switcher-container .device-switcher__icon-wrapper.icon-active svg rect,
.modal .modal__header .device-switcher-container .device-switcher__icon-wrapper.icon-active svg circle,
.modal .modal__header .device-switcher-container .device-switcher__icon-wrapper.icon-active svg path {
  fill: inherit;
  stroke: inherit;
  stroke: inherit;
}

.modal .title-container {
  text-align: center;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.modal .title-container h3 {
  font-weight: 500;
  font-size: 13px !important;
  color: #0f0f0f;
}

.modal .title-container .view-live-wrapper {
  margin-top: -2px;
  font-size: 10px;
  text-decoration: none;
}

.modal .title-container .view-live-wrapper h3 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.modal .title-container .view-live-wrapper h3 .project-icon-wrapper {
  position: absolute;
  right: -22px;
  top: 50%;
  transform: translateY(-50%);
}

.modal .title-container .view-live-wrapper h3 .project-icon-wrapper svg {
  display: block;
  height: 12px;
  fill: #0f0f0f;
}

.modal .modal__body {
  position: relative;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  padding-top: 20px;
}

.modal .modal__body iframe {
  width: 100%;
  height: 100%;
  border: none;
  position: absolute;
  top: 35px;
  left: 0;
}

.modal .modal__body .loading-spinner-container {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal .modal__body .loading-spinner-container a {
  text-decoration: underline;
  font-size: 12px;
  margin-top: 15px;
  color: #0b5183;
}

.modal .modal__body .loading-spinner-container .loading-spinner__body {
  font-size: 13px;
  color: #333333;
}

.modal .modal__footer {
  padding: 0.5rem;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 450ms, transform 450ms;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 450ms, transform 450ms;
}

@media only screen and (max-width: 965px) {
  .modal {
    width: 100%;
    top: 0;
    transform: translate(0, 0);
    left: 0;
    transform: translate(0) scale(0.92);
    height: 100vh;
  }
  .modal header {
    height: unset !important;
  }
  .btn-circle {
    width: 22px !important;
    height: 22px !important;
  }
}
/*# sourceMappingURL=DevicePreview.css.map */
.project-switcher-container {
  position: relative;
  display: flex;
  margin-bottom: 25px;
}

.project-switcher-container .category-item-wrapper {
  padding: 5px 10px;
  margin-right: 5px;
  position: relative;
}

.project-switcher-container .category-item-wrapper button {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  font-weight: inherit;
  outline: none;
}

.project-switcher-container .category-item-wrapper:hover {
  color: var(--blueText);
}

.project-switcher-container .category-item-wrapper.item-active {
  position: relative;
  color: var(--blueText);
  font-weight: 600;
}

.project-switcher-container .category-item-wrapper.item-active:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: var(--blueText);
  position: absolute;
  bottom: 0;
  left: 0;
}
/*# sourceMappingURL=ProjectSwitcher.css.map */
h1 {
  font-size: 20px;
  font-weight: 600;
}

#featured-projects {
  -ms-scroll-snap-type: y mandatory;
      -webkit-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory;
}

.project-list-container {
  overflow: auto;
  -ms-scroll-snap-type: x mandatory;
      -webkit-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
}

.project-list-container .project-item__image .img-wrapper img {
  object-fit: cover;
}

.featured-project__secondary {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
}

.featured-project__secondary .project-item-container {
  scroll-snap-align: center;
}

@media only screen and (max-width: 1000px) {
  .featured-project__secondary {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .featured-project__secondary {
    display: flex;
    grid-gap: 0px;
    gap: 0px;
    flex-wrap: nowrap;
  }
  .featured-project__secondary .project-item-container {
    margin-right: 20px;
    flex-basis: 30%;
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 280px;
  }
  .project-list-container {
    margin: 0 -1.5em;
    padding: 0 1.5em;
  }
}
/*# sourceMappingURL=ProjectsScreen.css.map */
.modern-project-item-container {
  width: 100%;
  font-size: 12px;
  position: relative;
  display: flex;
  height: 100vh;
  align-items: center;
  scroll-snap-align: center;
}

.modern-project-item-container.inverse {
  justify-content: flex-end;
}

.modern-project-item-container .modern-project__image {
  position: relative;
  width: 100%;
  max-width: 924px;
  height: 85vh;
  max-height: 620px;
  border-radius: 2px;
  border: 1px solid #e2e2e2;
}

.modern-project-item-container .modern-project__image img {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.modern-project-item-container .modern-project__image img:hover {
  content: "V I E W";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
}

.modern-project-item-container .modern-project__image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.176908) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.modern-project-item-container .modern-project__image:after:hover {
  content: "V I E W";
}

.modern-project-item-container .modern-project__content {
  position: absolute;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5rem 3.2rem;
  box-shadow: 0 1px 7px 3px rgba(0, 0, 0, 0.086);
  width: 100%;
  max-width: 640px;
  top: 50%;
  border-radius: 2px;
  transform: translateY(-50%);
}

.modern-project-item-container .modern-project__content::after {
  content: "";
  position: absolute;
  background-color: #ffffffe3;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  top: 0;
  left: 0;
  /* background: red; */
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modern-project-item-container .modern-project__content.right-center {
  right: 0;
}

.modern-project-item-container .modern-project__content.left-center {
  left: 0;
}

.modern-project-item-container .modern-project__content .interaction-icon-container {
  position: absolute;
  top: 25px;
  right: 15px;
  display: flex;
  z-index: 111111111;
}

.modern-project-item-container .modern-project__content .interaction-icon-container .icon-item-wrapper {
  margin: 0 8px;
  cursor: pointer;
}

.modern-project-item-container .modern-project__content .interaction-icon-container .icon-item-wrapper svg {
  display: block;
  height: 14px;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content {
  width: 100%;
  z-index: 111;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content h2 {
  letter-spacing: 2px;
  font-size: 24px;
  line-height: 1.5em;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content p.description {
  margin-top: 15px;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content .content-container {
  margin-top: 40px;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content .content-container .logo-container {
  display: flex;
  flex-wrap: wrap;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content .content-container .logo-container .logo-wrapper {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 30px 40px 0px;
  height: 32px;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content .content-container .logo-container .logo-wrapper .tooltip {
  position: absolute;
  background-color: #000000a9;
  color: #fff;
  padding: 1px 5px;
  border-radius: 4px;
  top: -15px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content .content-container .logo-container .logo-wrapper .tooltip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  bottom: -9px;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #000000a9 transparent transparent transparent;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content .content-container .logo-container .logo-wrapper svg {
  height: 48px;
  width: auto;
  max-width: 100px;
  max-height: 48px;
}

.modern-project-item-container .modern-project__content .modern-project-inner-content .content-container h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.modern-project-item-container .modern-project__content .grid-3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

.preview-overlay {
  background-color: rgba(0, 0, 0, 0.345);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.preview-overlay svg {
  display: block;
  width: 80px;
  height: 64px;
  fill: #ffffff;
}

.preview-overlay svg path {
  stroke: #ffffff !important;
}

.preview-overlay svg circle {
  stroke: #ffffff !important;
}

.preview-overlay span {
  color: #ffffff;
  text-shadow: 0 0 6px #000000a1;
}

@media only screen and (max-width: 650px) {
  .modern-project-item-container {
    flex-direction: column;
    height: auto;
    margin-top: 70px;
  }
  .modern-project-item-container .modern-project__image {
    height: 240px;
  }
  .modern-project-item-container .modern-project__content {
    position: relative;
    margin-top: -20%;
    padding: 3.5rem 1.4rem 1rem;
    transform: translateY(0);
    width: 94%;
  }
  .modern-project-item-container .modern-project__content .modern-project-inner-content h2 {
    font-size: 22px;
  }
  .modern-project-item-container .modern-project__content .modern-project-inner-content .content-container {
    margin-top: 30px;
  }
}
/*# sourceMappingURL=ProjectModern.css.map */
.tooltip-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-wrapper .tooltip {
  position: absolute;
  background-color: #000000a9;
  color: #fff;
  padding: 1px 5px;
  border-radius: 4px;
  top: -35px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}

.tooltip-wrapper .tooltip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  bottom: -9px;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #000000a9 transparent transparent transparent;
}
/*# sourceMappingURL=Tooltip.css.map */
/* No CSS *//*# sourceMappingURL=Nav.css.map */
.nav-link__active {
  position: relative;
  color: var(--blueText);
  font-weight: 700;
}
/*# sourceMappingURL=NavLinkItem.css.map */
/* No CSS *//*# sourceMappingURL=NavLinkList.css.map */
aside.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  height: 100vh;
  width: 100%;
  background: white;
}

aside.side-drawer .mobile-header {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

aside.side-drawer .mobile-header svg {
  width: 50px;
}

aside.side-drawer #cross-wrapper {
  position: absolute;
  right: 44px;
  top: 34px;
  background: #ffffff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

aside.side-drawer #cross-wrapper #times-icon {
  color: #1c1c1c;
  display: block;
  height: 100%;
}

aside.side-drawer #cross-wrapper #close-button-background {
  fill: red;
  width: 30px;
  position: absolute;
  left: 0;
  transform: translateX(29px);
  z-index: 10;
}

aside.side-drawer .nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

aside.side-drawer .nav-links li {
  margin: 30px 0;
  width: 120%;
}

aside.side-drawer .nav-links li a {
  display: flex;
  color: #757575;
}

aside.side-drawer .nav-links li a span {
  font-size: 23px;
  line-height: 1.4em;
}

aside.side-drawer .nav-links li a #logout {
  padding: 0.2rem 1.2rem;
}

aside.side-drawer .nav-links .nav-link-icon,
aside.side-drawer .nav-links .nav-link__active {
  display: flex;
  align-items: center;
}

aside.side-drawer .nav-links .nav-link-icon svg,
aside.side-drawer .nav-links .nav-link__active svg {
  height: 24px;
  margin-right: 15px;
  max-width: 22px;
  min-width: 18px;
}

aside.side-drawer .main-navigation__drawer-nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidenav-enter {
  transform: translateX(-120%);
  transition: transform 500ms;
}

.sidenav-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}

.sidenav-exit {
  transform: translateX(-120%);
  transition: transform 500ms;
}

.sidenav-exit-active {
  transform: translateX(-120%);
  transition: transform 500ms;
}

@media only screen and (min-width: 600px) {
  .nav-link-icon {
    display: none !important;
  }
}
/*# sourceMappingURL=SideDrawer.css.map */
header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 1em;
  box-shadow: var(--boxShadow);
  z-index: 1111;
  height: var(--headerHeight);
  background: #fffffff7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .header-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--pageMaxWidth);
  margin: 0 auto;
}

header .header-inner-wrapper svg#j-logo {
  width: 40px;
}

header .header-inner-wrapper a {
  position: relative;
}

header .header-inner-wrapper svg {
  width: 65px;
  display: block;
}

header .sidedrawer-toggle-button {
  display: none;
}

nav {
  position: relative;
}

nav ul {
  display: flex;
}

nav .nav-item-wrapper {
  margin: 0 2em;
  font-size: 14px;
  text-transform: uppercase;
  color: #676666;
  font-weight: 500;
  letter-spacing: 1px;
}

nav .nav-item-wrapper:hover {
  color: var(--blueText);
}

nav#side-drawer-nav ul {
  flex-direction: column;
}

nav .sidedrawer-toggle-button {
  background-color: transparent;
  border: none;
}

nav .sidedrawer-toggle-button svg {
  fill: #333;
  width: 24px;
}

@media only screen and (max-width: 600px) {
  #desktop-nav ul {
    display: none !important;
  }
  .sidedrawer-toggle-button {
    display: block !important;
  }
  nav#side-drawer-nav {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  nav#side-drawer-nav li {
    margin-bottom: 60px;
    font-size: 24px;
  }
}
/*# sourceMappingURL=Header.css.map */
footer {
  width: 100%;
  color: var(--darkGray);
  text-align: center;
  box-shadow: 0 0 1px 1px #00000014;
}

footer .curve {
  background: white;
  position: relative;
  overflow: hidden;
}

footer .curve svg {
  display: block;
}

footer .curve svg path {
  fill: var(--darkGray);
}

footer .inner-wrapper {
  padding: 25px 15px 15px;
  max-width: var(--pageMaxWidth);
  margin: 0 auto;
  position: relative;
}

footer .inner-wrapper h2 {
  font-weight: 400;
  font-size: 14px;
  color: #494949;
}

footer .inner-wrapper .icons-container {
  position: relative;
  margin: 0 auto;
}

footer .inner-wrapper .icons-container .icon-wrapper {
  display: inline-block;
}

footer .inner-wrapper .icons-container .icon-wrapper a {
  margin: 10px;
  color: #494949;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: 200ms ease;
}

footer .inner-wrapper .icons-container .icon-wrapper a:hover {
  background-color: #ffffff;
  color: var(--darkGray);
}

footer .inner-wrapper .icons-container .icon-wrapper a svg {
  color: inherit;
  height: 28px;
  display: block;
}

footer #copyright {
  color: #242222;
  padding: 8px;
  font-size: 12px;
}
/*# sourceMappingURL=Footer.css.map */
main.resume {
  background: #fafafa;
  padding-bottom: 80px;
  overflow: hidden;
}

main.resume .resume-wrapper {
  display: flex;
  justify-content: center;
  padding: 30px 15px;
  width: 100%;
}

main.resume .resume-wrapper canvas {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.095);
  width: 100% !important;
  height: auto !important;
}

main.resume .download {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  background-color: var(--orange);
  color: #ffffff;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
}

main.resume .download .icon-wrapper {
  position: relative;
  display: flex;
  margin-left: 8px;
}

main.resume .download .icon-wrapper svg {
  height: 14px;
  fill: #ffffff;
}
/*# sourceMappingURL=ResumeScreen.css.map */
